//:::::::::::::::::::::::::
import css from '../paperwork.module.css'
import {
  Path,
  Nav,
  Divider,
  Summary,
  ModelPaperwork,
  observer,
  useCallback,
  useNavigate,
  Button,
  useEffect,
  Types,
  ModelBooking,
  ModelModal,
} from 'src/_Shared/global'
import PaperworkWrap from '../PaperworkWrap'
import SummaryPatientInfo from './SummaryPatientInfo'
import SummaryPatientAddress from './SummaryPatientAddress'
import SummaryPatientEmergency from './SummaryPatientEmergency'
import SummaryGuarantorInfo from './SummaryGuarantorInfo'
import SummaryGuarantorAddress from './SummaryGuarantorAddress'
import SummaryEmployerRequest from './SummaryEmployerRequest'
import SummaryPaymentInsurance from './SummaryPaymentInsurance'
import SummaryPaymentInsuranceSecondary from './SummaryPaymentInsuranceSecondary'
import SummaryInfoRelease from './SummaryInfoRelease'
import SummaryForms from './SummaryForms'
import SummaryPhotoID from './SummaryPhotoID'
import { useAuth0 } from '@auth0/auth0-react'
import { useAccountStore } from 'src/_Shared/stores/account'
import { useInsuranceStore } from 'src/_Shared/stores/insurance'
import moment from 'moment'
//:::::::::::::::::::::::::

function PaperworkSummary() {
  const navigate = useNavigate()
  const { isAuthenticated } = useAuth0()
  const [idToken] = useAccountStore((state) => [state.idToken])
  const [
    createInsurance,
    frontImage,
    backImage,
    cardName,
    uploadDocuments,
    insurances,
  ] = useInsuranceStore((state) => [
    state.createInsurance,
    state.frontImage,
    state.backImage,
    state.cardName,
    state.uploadDocuments,
    state.insurances,
  ])

  useEffect(() => {
    //Prep Booking ID
    if (localStorage.getItem('bookingSummary')) {
      const booking = JSON.parse(
        localStorage.getItem('bookingSummary')!,
      ) as Types.BookingSummary
      ModelBooking.setBookingId(booking.bookingId)
    }
  }, [])

  //==============================
  //#region Navigate Back to Edit
  //==============================
  const clickSummary = useCallback(
    (path: string) => {
      ModelPaperwork.setBackToSummary(true)
      //>>>>>
      navigate(path)
    },
    [navigate],
  )
  //==============================
  //#region Submit Form
  //==============================
  const clickSubmit = useCallback(async () => {
    ModelModal.showLoader('Please Wait', 'Submitting paperwork...')
    try {
      console.log('🔄 Submitting paperwork...')
      await ModelPaperwork.submit()
      await ModelPaperwork.submitConsentData()

      //+++++
      if (localStorage.getItem('paperwork')) {
        console.log('🔄 Sending data to native app...')
        sendDataToNativeApps(localStorage.getItem('paperwork')!)
      }

      await uploadDocuments()
      const insuranceAlreadyExists = insurances?.find(
        (ins) => ins.backImage === backImage && ins.frontImage === frontImage,
      )

      if (frontImage && backImage && idToken && !insuranceAlreadyExists) {
        await createInsurance(
          frontImage,
          backImage,
          `${cardName} - ${moment().format('MM/DD/YYYY - h:mm A')}`,
          idToken,
        )
      }
      ModelModal.hide()
    } catch (error) {
      console.error('🚫 Error submitting paperwork:', error)
      ModelModal.hide()
    }

    console.log('✅ Paperwork submitted and data sent.')
    if (isAuthenticated) {
      //>>>>>
      navigate(Path.BookFinish)
    } else {
      //>>>>>
      navigate(Path.PaperworkSaveInfo)
    }
  }, [isAuthenticated, navigate])

  //==============================
  //#region Send to Native Apps
  //==============================
  function sendDataToNativeApps(data: string) {
    //::::::::::::::::::
    //Send to iOS
    //::::::::::::::::::
    //@ts-ignore
    window?.webkit?.messageHandlers?.AccountBridge?.postMessage({
      message: data,
    })

    //::::::::::::::::::
    //Send to Android
    //::::::::::::::::::
    //@ts-ignore
    if (typeof AccountBridge !== 'undefined') {
      //@ts-ignore
      AccountBridge?.setAccount(data)
    }
  }

  //==============================
  //#region UI
  //==============================
  return (
    <PaperworkWrap>
      {/* === Nav === */}
      <Nav back={Path.Paperwork5} title='Review and Accept' progress={6 / 6} />
      <div className={css.paperwork}>
        <div className={css.paperworkInner}>
          <h2>
            Review and confirmation all the details below. If everything looks
            good, we’ll send to the provider.
          </h2>
          <Divider />
          {/* === Patient Information === */}
          <Summary
            title='Patient Information'
            clicked={() => clickSummary(Path.Paperwork1)}
          >
            <SummaryPatientInfo />
          </Summary>
          {/* === Patient Address === */}
          <Summary
            title='Patient Address'
            clicked={() => clickSummary(Path.Paperwork1)}
          >
            <SummaryPatientAddress />
          </Summary>
          {/* === Emergency Contact === */}
          <Summary
            title='Emergency Contact'
            clicked={() => clickSummary(Path.Paperwork1)}
          >
            <SummaryPatientEmergency />
          </Summary>
          {/* === Guarantor Information === */}
          <Summary
            title='Guarantor Information'
            clicked={() => clickSummary(Path.Paperwork2)}
          >
            <SummaryGuarantorInfo />
          </Summary>
          {/* === Guarantor Address === */}
          <Summary
            title='Guarantor Address'
            clicked={() => clickSummary(Path.Paperwork2)}
          >
            <SummaryGuarantorAddress />
          </Summary>

          {ModelPaperwork.paperwork.workInjury ? (
            <>
              {/* === Employer === */}
              <Summary
                title='Employer Request'
                clicked={() => clickSummary(Path.Paperwork3)}
              >
                <SummaryEmployerRequest />
              </Summary>
            </>
          ) : ModelPaperwork.paperwork.selfPay ? (
            <Summary
              title='Self-Pay'
              clicked={() => clickSummary(Path.Paperwork4)}
            >
              <SummaryPhotoID />
            </Summary>
          ) : (
            <>
              {/* === Insurance === */}
              <Summary
                title='Primary Insurance'
                clicked={() => clickSummary(Path.Paperwork4)}
              >
                <SummaryPaymentInsurance />
              </Summary>
              <Summary
                title='Secondary Insurance'
                clicked={() => clickSummary(Path.Paperwork4)}
              >
                <SummaryPaymentInsuranceSecondary />
              </Summary>
              <Summary
                title='Photo ID'
                clicked={() => clickSummary(Path.Paperwork4)}
              >
                <SummaryPhotoID />
              </Summary>
            </>
          )}

          {/* === Info Release === */}
          <Summary
            title='Authorization of Information Release'
            clicked={() => clickSummary(Path.Paperwork5)}
          >
            <SummaryInfoRelease />
          </Summary>

          {/* === Review and Accept Forms === */}
          <SummaryForms />
        </div>
      </div>

      {/* === Finish Booking Button === */}
      <div className={css.buttonWrap}>
        <Button
          primary
          text='Submit Paperwork'
          disabled={!ModelPaperwork.formReady}
          clicked={clickSubmit}
        />
      </div>
    </PaperworkWrap>
  )
}

export default observer(PaperworkSummary)
